<template>
  <div>
    <div v-if="isSupported">
      <Header v-if="!isLoginPage"/>
      <!-- 背景图加给main盒子 -->
      <div class="main">
        <router-view/>
        <Footer v-if="!isLoginPage"/>
      </div>
    </div>
    <div v-else>
      <div>
        <p v-if="unsupportedBrowser === 'IE'">
          你正在使用的是 Internet Explorer 浏览器，请使用其他浏览器访问。<br>
          Internet Explorer 不支持现代 Web 技术，推荐使用 Edge、Chrome、Firefox 或 Safari。
        </p>
        <p v-else-if="unsupportedBrowser === 'Edge'">
          你正在使用的是旧版本的 Microsoft Edge 浏览器（Edge 18 及以下），请升级到最新版本的 Edge 或使用 Chrome、Firefox 或 Safari。
        </p>
        <p v-else-if="unsupportedBrowser === 'Firefox'">
          你正在使用的是旧版本的 Firefox 浏览器，请升级到最新版本的 Firefox 或使用 Chrome、Edge 或 Safari。
        </p>
        <p v-else-if="unsupportedBrowser === 'Chrome'">
          你正在使用的是旧版本的 Chrome 浏览器，请升级到最新版本的 Chrome 或使用 Edge、Firefox 或 Safari。
        </p>
        <p v-else-if="unsupportedBrowser === 'Safari'">
          你正在使用的是旧版本的 Safari 浏览器，请升级到最新版本的 Safari 或使用 Chrome、Edge 或 Firefox。
        </p>
        <p v-else>
          抱歉，本应用不支持您当前使用的浏览器，请使用现代浏览器访问，如谷歌，火狐等。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      isLoginPage: false,
      isSupported: true,
      unsupportedBrowser: ''
    }
  },
  watch: {
    $route(to) {
      this.isLoginPage = to.path === '/login';
    }
  },
  created() {
    this.isLoginPage = this.$route.path === '/login';
  },
  mounted() {
    this.checkBrowserSupport();
  },
  methods: {
    checkBrowserSupport() {
      const userAgent = window.navigator.userAgent;
      // 检测是否是 IE 或 Trident 引擎的浏览器
      if (/MSIE |Trident\//.test(userAgent)) {
        this.isSupported = false;
        this.unsupportedBrowser = 'IE';
        console.log('ie')
        return;
      }
      // 检测 Edge 浏览器（Edge 18 及以下版本）
      if (/Edge\/\d+/.test(userAgent)) {
        const edgeVersion = parseInt(userAgent.match(/Edge\/(\d+)/)[1], 10);
        console.log('Edge' . edgeVersion)
        if (edgeVersion <= 18) {
          this.isSupported = false;
          this.unsupportedBrowser = 'Edge';
          return;
        }
      }
      // 检测 Firefox 浏览器（旧版本）
      if (/Firefox\/\d+/.test(userAgent)) {
        const firefoxVersion = parseInt(userAgent.match(/Firefox\/(\d+)/)[1], 10);
        console.log('Firefox' . firefoxVersion)
        // 在这里添加适当的版本检测条件
        // 例如，如果 firefoxVersion 小于某个特定版本，则设置 this.isSupported = false;
        if (firefoxVersion < 60) {
          this.isSupported = false;
          this.unsupportedBrowser = 'Firefox';
          return;
        }
      }
      // 检测 Chrome 浏览器（旧版本）
      if (/Chrome\/\d+/.test(userAgent)) {
        const chromeVersion = parseInt(userAgent.match(/Chrome\/(\d+)/)[1], 10);
        console.log('Chrome' . chromeVersion)
        // 在这里添加适当的版本检测条件
        // 例如，如果 chromeVersion 小于某个特定版本，则设置 this.isSupported = false;
        if (chromeVersion < 70) {
          this.isSupported = false;
          this.unsupportedBrowser = 'Chrome';
          return;
        }
      }
      // 检测 Safari 浏览器（旧版本）
      // if (/Safari\//.test(userAgent)) {
      //   // 在 macOS 上的 Safari 用户代理字符串可能包含 Safari 版本信息
      //   // 可以根据需要添加适当的版本检测条件
      //   this.isSupported = false;
      //   this.unsupportedBrowser = 'Safari';
      // }
    }
  }
}
</script>
<style scoped>
.main{
  background: url('../src/assets/images/bgsevers.png');
    background-repeat: repeat;
    background-position: 50%;
    background-size: contain;
}

</style>
