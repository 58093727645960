<template>
  <div class="footer-container">
    <div class="link">
      <div style="width: 100%;">
        <el-row>
          <el-col :span="8">
            <div style="cursor: pointer;" @click="toInfo('/pc/info?type=map')">网站地图</div>
          </el-col>
          <el-col :span="8">
            <div style="cursor: pointer;" @click="toInfo('/pc/info?type=contact')">联系我们</div>
          </el-col>
          <el-col :span="8">
            <div style="cursor: pointer;" @click="toInfo('/pc/info?type=copyright')">版权声明</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <div class="copyright">
        <a class="custom-link" href="https://beian.miit.gov.cn" target="_blank">{{ info?.ba_info }}</a>
      </div>
    </div>
    <div>
      <div class="qrcode-row">
          <div class="qrcode-content">
            <img style="width: 100px; height: 100px;" :src="info?.m_qrcode" alt="">
            <div class="qrcode-text1">手机版访问</div>
          </div>
          <div class="qrcode-content">
            <img style="width: 100px; height: 100px;" :src="info?.mini_qrcode" alt="">
            <div class="qrcode-text1">微信扫码访问</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "@/utils/request";

export default {
  name: 'Footer',
  created() {
    this.getSystem()
  },
  data() {
    return {
      info: {}
    }
  },
  methods: {
    getSystem() {
      get('/getSystem', {fields: 'ba_info,m_qrcode,mini_qrcode'}).then(res => {
        this.info = res.data
      }).catch(() => {
      })
    },
    toInfo(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.footer-container {
  color: #B5AAA5;
  margin-top: 65px;

  .link {
    padding: 10px 0;
    width: 30%;
    margin: 0 auto;
    text-align: center;
  }

  .copyright {
    padding: 10px;
    text-align: center; /* 让 a 标签居中 */
    .custom-link {
      text-decoration: none; /* 去掉下划线 */
      color: #b5aaa5;
    }
  }



  .qrcode-row {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 图片和文字居中 */
    .qrcode-content {
      margin: 10px;
      display: flex;
      flex-direction: column; /* 让图片和文字纵向排列 */
      align-items: center; /* 内容水平居中 */
    }
  }


}

</style>
