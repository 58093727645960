import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import {getToken} from "@/utils/function";

// create an axios instance
const service = axios.create({
  baseURL: 'https://www.ichhm.cn/webapi', // url = base url + request url
  // baseURL: 'http://adminwebsite.xjdft.com/webapi', // url = base url + request url
  // baseURL: 'http://local.website.com/webapi', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = getToken()
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + token
      // let token = ''
      // config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 0) {
      return res
    }
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 3 * 1000
    })

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: '重新登录',
        cancelButtonText: '返回',
        type: 'warning'
      }).then(() => {
        this.$router.push({path: '/login'}).then(r => {
          console.log(r)
        })
      })
    }
    return Promise.reject(res)
    // return Promise.reject(new Error(res.message || 'Error'))
  },
  error => {
    console.log('err' + error) // for debug
    console.error('axios response error:', error);
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export function post(url, params) {
  return service({
    method: 'post',
    url,
    data: params
  }).then(response => {
    return response
  }).catch(error => {
    return Promise.reject(error)
  })
}

export function get(url, params = {}) {
  return service({
    method: 'get',
    url,
    params
  }).then(response => {
    return response
  }).catch(error => {
    return Promise.reject(error)
  })
}

export function showMsg(msg) {
  Message({
    message: msg,
    type: 'success',
    duration: 2000
  })
}

export function showError(msg) {
  Message({
    message: msg,
    type: 'error',
    duration: 2000
  })
}

export default service
