import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    meta: {title: '首页'},
    redirect: '/pc/index'
  },
  {
    name: 'Index',
    path: '/pc/index',
    meta: {title: '首页'},
    component: () => import('@/views/index/index.vue')
  },
  // 机构
  {
    name: 'Organ',
    path: '/pc/organ',
    meta: {title: '简介'},
    component: () => import('@/views/organ/organ.vue')
  },
  //政策
  {
    name: 'Policy',
    path: '/pc/policy',
    meta: {title: '政策'},
    component: () => import('@/views/policy/policy.vue')
  },
  // 咨询
  {
    name: 'Consult',
    path: '/pc/consult',
    meta: {title: '资讯'},
    component: () => import('@/views/consult/consult.vue')
  },
  {
    name: 'ConsultList',
    path: '/pc/consult-list',
    meta: {title: '资讯列表'},
    component: () => import('@/views/consult/consult-list.vue')
  },
  {
    name: 'SubjectList',
    path: '/pc/subject-list',
    meta: {title: '专题报告'},
    component: () => import('@/views/consult/subject-list.vue')
  },
  // 清单
  {
    name: 'Project',
    path: '/pc/project',
    meta: {title: '非物质文化遗产代表性项目'},
    component: () => import('@/views/list/project.vue')
  },
  {
    name: 'ProjectDetail',
    path: '/pc/project-detail',
    meta: {title: ''},
    component: () => import('@/views/list/project-detail.vue')
  },
  {
    name: 'Inheritor',
    path: '/pc/inheritor',
    meta: {title: '非物质文化遗产代表性传承人'},
    component: () => import('@/views/list/inheritor.vue')
  },
  {
    name: 'InheritorDetail',
    path: '/pc/inheritor-detail',
    meta: {title: ''},
    component: () => import('@/views/list/inheritor-detail.vue')
  },
  {
    name: 'Protect',
    path: '/pc/protect',
    meta: {title: '保护基地和传统工艺工作站'},
    component: () => import('@/views/list/protect.vue')
  },
  {
    name: 'ProtectDetail',
    path: '/pc/protect-detail',
    meta: {title: ''},
    component: () => import('@/views/list/protect-detail.vue')
  },
  // 资源
  {
    name: 'Source',
    path: '/pc/source',
    meta: {title: '资源'},
    component: () => import('@/views/source/source.vue')
  },
  {
    name: 'SourceList',
    path: '/pc/source-list',
    meta: {title: '资源列表'},
    component: () => import('@/views/source/source-list.vue')
  },
  {
    name: 'ShowDetail',
    path: '/pc/show-detail',
    meta: {title: ''},
    component: () => import('@/views/source/show-detail.vue')
  },
  {
    name: 'VideoDetail',
    path: '/pc/video-detail',
    meta: {title: ''},
    component: () => import('@/views/source/video-detail.vue')
  },
  {
    name: 'MusicDetail',
    path: '/pc/music-detail',
    meta: {title: ''},
    component: () => import('@/views/source/music-detail.vue')
  },
  {
    name: 'ImageDetail',
    path: '/pc/image-detail',
    meta: {title: ''},
    component: () => import('@/views/source/image-detail.vue')
  },
  //百科
  {
    name: 'Wiki',
    path: '/pc/wiki',
    meta: {title: '百科'},
    component: () => import('@/views/wiki/wiki.vue')
  },
  // 搜索
  {
    name: 'Search',
    path: '/pc/search',
    meta: {title: '搜索'},
    component: () => import('@/views/search/search.vue')
  },
  // detail
  {
    name: 'Detail',
    path: '/pc/detail',
    meta: {title: ''},
    component: () => import('@/views/detail.vue')
  },
  {
    name: 'Info',
    path: '/pc/info',
    meta: {title: ''},
    component: () => import('@/views/info.vue')
  },
  {
    name: 'login',
    path: '/pc/login',
    component: () => import('@/views/login.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' - 哈密非物质文化遗产中心'
  }

  next()
})

export default router;
