export function isLogin() {
  return !!localStorage.getItem('website_pc_token')
}

export function loginOut() {
  localStorage.removeItem('website_pc_token')
  localStorage.removeItem('website_pc_nickname')
  localStorage.removeItem('website_pc_avatar')
}

export function setToken(token) {
  return localStorage.setItem('website_pc_token', token)
}

export function getToken() {
  return localStorage.getItem('website_pc_token')
}

export function setInfo(info) {
  localStorage.setItem('website_pc_nickname', info.nickname)
  if (info.avatar) {
    localStorage.setItem('website_pc_avatar', info.avatar)
  }
}

export function getInfo() {
  return {
    nickname: localStorage.getItem('website_pc_nickname'),
    avatar: localStorage.getItem('website_pc_avatar')
  }
}
